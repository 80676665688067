import React from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import Training from '../../../components/Training';

function KidsTrainingTamworth() {
  return (
    <>
      <Helmet>
        <title>
          Children&apos;s Karate Tamworth, Children&apos;s Self Defence
        </title>
        <meta name="keywords" content="children's karate tamworth, children's self defence tamworth" />
        <meta name="description" content="Hills Combat Children's Karate Tamworth offers fun self-defence and karate classes for kids aged 4-16. Join us today to enhance your child's skills and confidence!" />
      </Helmet>
      <PageHeader name="Children&apos;s Karate Tamworth" />
      <Training
        club="tamworth"
        name="Children&apos;s Karate Tamworth"
        image="/images/gallery/childrens-karate-tamworth.jpg"
      >
        <>
          <p className="excerpt">
            Welcome to Hills Combat
            {' '}
            <strong>Children&apos;s Karate Tamworth</strong>
            ,
            offering engaging self-defence and karate classes for children ages 4-16.
            Held every Wednesday from 7 pm to 8 pm at Newton Regis Village Hall, our classes are designed to cater to
            different age groups, ensuring each child receives the appropriate level of instruction and attention.
          </p>

          <hr />
          <h6 className="excerpt">
            Younger Group (Ages 4-7)
          </h6>
          <hr />

          <p>
            Our younger group session introduces children to the basics of karate and self-defence.
            These energetic classes channel your child&apos;s enthusiasm into fun and productive activities, teaching
            them valuable life skills such as respect, discipline, and conflict resolution.
          </p>

          <hr />
          <h6 className="excerpt">
            Older Group (Ages 8-16)
          </h6>
          <hr />
          <p>
            The older group session is equally dynamic, focusing on the fundamentals of self-defence, karate techniques,
            and safety. We emphasise conflict resolution and anti-bullying strategies, essential skills for
            children in this age range.
          </p>

          <hr />
          <h6 className="excerpt">
            Skills &amp; Techniques Taught
          </h6>
          <hr />

          <p>All children attending our classes will learn:</p>

          <ul className="list-styled">
            <li>Respect</li>
            <li>Discipline</li>
            <li>Conflict Resolution</li>
            <li>Effective Kicking Techniques</li>
            <li>Punching Skills</li>
            <li>Defensive Blocking</li>
            <li>Traditional Kata</li>
            <li>Punch &amp; kick bag drills</li>
            <li>Sparring</li>
          </ul>

          <p>
            These skills are taught progressively, becoming more advanced as the student progresses through
            the grade levels. More information about our
            {' '}
            <Link to="/karate/grading-system">Karate Grading System</Link>
            {' '}
            , including Kyu grades and frequently
            asked questions, can be found on our members website.
          </p>

          <hr />
          <h6 className="excerpt">
            Benefits of Karate for Children
          </h6>
          <hr />

          <p>
            Karate provides numerous benefits, including improved physical fitness, self-confidence, self-discipline,
            and focus. It&apos;s also a fantastic way for children to develop social skills and make new friends.
          </p>

          <hr />
          <h6 className="excerpt">
            Join Hills Combat Karate Today!
          </h6>
          <hr />

          <p>
            Hills Combat Children&lsquo;s Karate in Tamworth is proud to be affiliated with the
            {' '}
            <Link to="/karate/the-english-karate-organisation">English Karate Organisation (EKO)</Link>
            {' '}
            . This membership offers our students access to a recognised grading authority and various other benefits.
            Our experienced instructors, Sensei Mike and Sensei Rob, are EKO-approved and have a wealth of experience
            working with young students. Everyone is welcome at Hills Combat. We encourage you to bring your
            child for a free trial class and experience the benefits of karate first-hand.
          </p>

          <p>
            <hr />
            <strong>Location:</strong>
            {' '}
            Newton Regis Village Hall, Austrey Lane, Tamworth, B79 0NL
            {' '}
            <br />
            <hr />
            <strong>Class Time:</strong>
            {' '}
            Every Wednesday, 7 pm - 8 pm
            <hr />
          </p>

          <p>
            For more information about our kids&apos; karate classes in Tamworth, feel free to contact Rob at
            {' '}
            <a href="tel:+447930339609" title="Call Rob">07930 339609</a>
            {' '}
            or Mike at
            {' '}
            <a href="tel:+447860465577" title="Call Mike">07860 465577</a>
            .
            We&apos;re here to answer any questions you may have and provide you with all the details you need.
            Join Hills Combat Karate and give your child the gift of confidence, discipline, and fun!
          </p>
        </>
      </Training>
    </>
  );
}

export default KidsTrainingTamworth;

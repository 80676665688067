import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import PageHeader from '../components/PageHeader';

interface IProduct {
  featured: boolean
  name: string
  price: number
  perPriceUnit: string
  benefits: string[]
}

function Product({
  name, price, perPriceUnit, benefits, featured,
}: IProduct) {
  return (
    <div className="col-lg-4 col-md-6">
      <div className={`${featured ? 'plan-featured' : ''} pricing-plan box-shadow`}>
        <div className="plan-name">
          <h3>{name}</h3>
        </div>
        <div className="price-wrap color-darkgrey">
          <span className="plan-sign">£</span>
          <span className="plan-price">{price}</span>
          <span className="plan-decimals">.00</span>
        </div>
        <div className="plan-description small-text color-darkgrey">
          Per
          {' '}
          {perPriceUnit}
        </div>
        <div className="plan-features">
          <ul className="list-bordered">
            {benefits.map((benefit) => <li key={benefit}>{benefit}</li>)}
          </ul>
        </div>
        <div className="plan-button">
          <Link className="btn btn-maincolor" to="/contact-us">Contact</Link>
        </div>
      </div>
    </div>
  );
}

function Pricing() {
  return (
    <>
      <Helmet>
        <title>Pricing | Hills Combat</title>
      </Helmet>
      <PageHeader name="Pricing" />
      <section className="ds s-pt-60 s-pb-40 s-pt-md-90 s-pb-md-60">
        <div className="container">
          <div className="row">
            <Product
              key={1}
              featured={false}
              name="1st Family Member"
              price={32}
              perPriceUnit="Month"
              benefits={[
                'Train every week',
                'Improve fitness',
                'Goal setting & self improvement',
                'Learn about conflict resolution',
                'Be apart of our club',
              ]}
            />
            <Product
              key={2}
              featured
              name="Additional Family Members"
              price={22}
              perPriceUnit="Month"
              benefits={[
                'Train every week',
                'Improve fitness',
                'Goal setting & self improvement',
                'Learn about conflict resolution',
                'Be apart of our club',
              ]}
            />
            <Product
              key={3}
              featured={false}
              name="Private Training"
              price={32}
              perPriceUnit="Hour"
              benefits={[
                '1-2-1 training with our instructors',
                'Achieve goals quicker',
                'Get individual expert advice',
                'Boost motivation',
                'Flexible schedule & format',
              ]}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default Pricing;

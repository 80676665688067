import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import EKO from './pages/EKO';
import About from './pages/About';
import Sitemap from './pages/Sitemap';
import Pricing from './pages/Pricing';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';
import PrivateTraining from './pages/training/Private';
import Benefits from './pages/Benefits';
import GradingSystem from './pages/training/GradingSystem';
import KidsTrainingAshby from './pages/training/Kids/Ashby';
import KidsTrainingMoira from './pages/training/Kids/Moira';
import KidsTrainingMeasham from './pages/training/Kids/Measham';
import KidsTrainingSwadlincote from './pages/training/Kids/Swadlincote';
import KidsTrainingBurntwood from './pages/training/Kids/Burntwood';
import KidsTrainingHednesford from './pages/training/Kids/Hednesford';
import KidsTrainingTamworth from './pages/training/Kids/Tamworth';
import KidsTrainingNewtonRegis from './pages/training/Kids/NewtonRegis';
import KidsTrainingPolesworth from './pages/training/Kids/Polesworth';
import RobHills from './pages/instructors/RobHills';
import MikeHills from './pages/instructors/MikeHills';
import Dashboard from './pages/training/Online/Dashboard';
import HeianShodan from './pages/training/Online/kata/HeianShodan';
import HeianNidan from './pages/training/Online/kata/HeianNidan';
import HeianSandan from './pages/training/Online/kata/HeianSandan';
import HeianYondan from './pages/training/Online/kata/HeianYondan';
import MawashiGeri from './pages/training/Online/techniques/MawashiGeri';
import ShutoUke from './pages/training/Online/techniques/ShutoUke';
import Gyakuzuki from './pages/training/Online/techniques/Gyakuzuki';
import Header from './components/Header';
import Footer from './components/Footer';
import MtCaptcha from './components/MtCaptcha';
import TokenProvider from './TokenProvider';

import './App.css';

function App() {
  const { pathname } = useLocation();
  const preloader = document.getElementById('preloader');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (preloader) {
      setTimeout(() => {
        preloader.style.display = 'none';
      }, 2000);
    }
  });

  return (
    <TokenProvider initialTokenValue="">
      <MtCaptcha />
      <Header />
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route caseSensitive path="/" element={<Home />} />
        <Route caseSensitive path="/sitemap" element={<Sitemap />} />
        <Route caseSensitive path="/about-us" element={<About />} />
        <Route caseSensitive path="/pricing" element={<Pricing />} />
        <Route caseSensitive path="/contact-us" element={<Contact />} />
        <Route path="/karate">
          <Route caseSensitive path="private-tuition" element={<PrivateTraining />} />
          <Route caseSensitive path="the-english-karate-organisation" element={<EKO />} />
          <Route caseSensitive path="grading-system" element={<GradingSystem />} />
          <Route caseSensitive path="benefits-for-children" element={<Benefits />} />
        </Route>
        <Route caseSensitive path="/childrens-karate-ashby-de-la-zouch" element={<KidsTrainingAshby />} />
        <Route caseSensitive path="/childrens-karate-measham" element={<KidsTrainingMeasham />} />
        <Route caseSensitive path="/childrens-karate-moira" element={<KidsTrainingMoira />} />
        <Route caseSensitive path="/childrens-karate-swadlincote" element={<KidsTrainingSwadlincote />} />
        <Route caseSensitive path="/childrens-karate-burntwood" element={<KidsTrainingBurntwood />} />
        <Route caseSensitive path="/childrens-karate-hednesford" element={<KidsTrainingHednesford />} />
        <Route caseSensitive path="/childrens-karate-tamworth" element={<KidsTrainingTamworth />} />
        <Route caseSensitive path="/childrens-karate-newton-regis" element={<KidsTrainingNewtonRegis />} />
        <Route caseSensitive path="/childrens-karate-polesworth" element={<KidsTrainingPolesworth />} />
        <Route path="instructors">
          <Route caseSensitive path="sensei-mike-hills" element={<MikeHills />} />
          <Route caseSensitive path="sensei-rob-hills" element={<RobHills />} />
        </Route>
        <Route path="members">
          <Route caseSensitive path="login" element={<Login />} />
          <Route caseSensitive path="dashboard" element={<Dashboard />} />
        </Route>
        <Route path="kata">
          <Route caseSensitive path="heian-shodan" element={<HeianShodan />} />
          <Route caseSensitive path="heian-nidan" element={<HeianNidan />} />
          <Route caseSensitive path="heian-sandan" element={<HeianSandan />} />
          <Route caseSensitive path="heian-yondan" element={<HeianYondan />} />
        </Route>
        <Route path="techniques">
          <Route caseSensitive path="mawashi-geri" element={<MawashiGeri />} />
          <Route caseSensitive path="shuto-uke" element={<ShutoUke />} />
          <Route caseSensitive path="gyakuzuki" element={<Gyakuzuki />} />
        </Route>
      </Routes>
      <Footer />
    </TokenProvider>
  );
}

export default App;

import React from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import Training from '../../../components/Training';

function KidsTrainingPolesworth() {
  return (
    <>
      <Helmet>
        <title>
          Children&apos;s Karate Polesworth, Children&apos;s Self Defence
        </title>
        <meta name="keywords" content="children's karate polesworth, children's self defence polesworth" />
        <meta name="description" content="Hills Combat Children's Karate in Polesworth provides structured and engaging self-defence and karate training for kids aged 4-16. Enrol today and help your child build confidence and skills!" />
      </Helmet>
      <PageHeader name="Children&apos;s Karate Polesworth" />
      <Training
        club="tamworth"
        name="Children&apos;s Karate Polesworth"
        image="/images/gallery/childrens-karate-polesworth.jpg"
      >
        <>
          <p className="excerpt">
            Welcome to
            {' '}
            <strong>Hills Combat Children&apos;s Karate Polesworth</strong>
            ,
            where we offer top-quality karate classes for kids in the local area.
            Our expert instructors work with children aged 4 to 16, helping them
            develop essential skills and discipline through the practice of karate.
            Conveniently located just a 10 minute drive from Polesworth, our classes
            are easily accessible for families nearby.
          </p>

          <hr />
          <h6 className="excerpt">Younger Group (Ages 4-7)</h6>
          <hr />

          <p>
            Our beginner-friendly sessions introduce young children to karate fundamentals and self-defence. These fun,
            movement-based classes encourage discipline, respect, and problem-solving skills while keeping kids engaged and active.
          </p>

          <hr />
          <h6 className="excerpt">Older Group (Ages 8-16)</h6>
          <hr />

          <p>
            This group builds on foundational karate techniques, incorporating self-defence strategies and anti-bullying principles.
            Sessions focus on improving coordination, confidence, and focus while equipping students with practical skills for safety.
          </p>

          <hr />
          <h6 className="excerpt">Skills Your Child Will Acquire</h6>
          <hr />

          <ul className="list-styled">
            <li>Respect and Self-Discipline</li>
            <li>Techniques for Resolving Conflicts</li>
            <li>Effective Kicking and Punching Skills</li>
            <li>Defensive Blocking and Traditional Kata Forms</li>
            <li>Drills with Punch and Kick Bags</li>
            <li>Sparring Techniques and Gradual Grading Advancement</li>
          </ul>

          <p>
            As learners advance, they continue to perfect their techniques and progress through our organised grading system. For more information about Kyu ranks and progression, visit our members&apos; website.
          </p>

          <hr />
          <h6 className="excerpt">Benefits of Karate for Your Child</h6>
          <hr />

          <p>
            Karate offers a great opportunity to improve physical fitness, increase self-esteem, sharpen concentration, and develop discipline. It also promotes teamwork and social engagement, making it an ideal activity for children.
          </p>

          <hr />
          <h6 className="excerpt">Join Hills Combat Karate Today!</h6>
          <hr />

          <p>
            Hills Combat Children&lsquo;s Karate is officially partnered with the
            {' '}
            <Link to="/karate/the-english-karate-organisation">English Karate Organisation (EKO)</Link>
            , providing students with a reputable grading system and exclusive membership perks. Our skilled instructors, Sensei Mike and Sensei Rob, bring years of experience in teaching kids and creating a supportive learning environment.
          </p>

          <p>
            <hr />
            <strong>Location:</strong>
            {' '}
            Newton Regis Village Hall, Austrey Lane, Tamworth, B79 0NL
            <br />
            <hr />
            <strong>Class Time:</strong>
            {' '}
            Every Wednesday from 7:00 PM to 8:00 PM
            <hr />
          </p>

          <p>
            Want to give a class a try? Reach out to Rob at
            <a href="tel:+447930339609" title="Call Rob">07930 339609</a>
            or Mike at
            <a href="tel:+447860465577" title="Call Mike">07860 465577</a>
            .
            Contact us today to book a free trial and discover how our classes can help your child build confidence, stay active, and acquire new skills in a friendly and supportive setting!
          </p>
        </>
      </Training>
    </>
  );
}

export default KidsTrainingPolesworth;

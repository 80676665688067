import React from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import Training from '../../../components/Training';

function KidsTrainingNewtonRegis() {
  return (
    <>
      <Helmet>
        <title>
          Children&apos;s Karate Newton Regis | Fun & Structured Martial Arts
        </title>
        <meta name="keywords" content="karate for kids Newton Regis, self-defence classes for children" />
        <meta name="description" content="Engaging and structured children's karate classes in Newton Regis, helping kids build confidence, discipline, and self-defence skills." />
      </Helmet>
      <PageHeader name="Children&apos;s Karate Newton Regis" />
      <Training
        club="tamworth"
        name="Children&apos;s Karate Newton Regis"
        image="/images/gallery/childrens-karate-newton-regis.jpg"
      >
        <>
          <p className="excerpt">
            Welcome to
            {' '}
            <strong>Hills Combat Children&apos;s Karate in Newton Regis</strong>
            .
            Our classes provide a dynamic and supportive environment where children can learn self-defence, build confidence, and develop essential life skills through structured martial arts training.
          </p>

          <hr />
          <h6 className="excerpt">Younger Group (Ages 4-7)</h6>
          <hr />

          <p>
            Designed for beginners, these sessions focus on fundamental karate techniques while keeping training engaging and interactive. Kids will improve coordination, listening skills, and self-discipline in a fun setting.
          </p>

          <hr />
          <h6 className="excerpt">Older Group (Ages 8-16)</h6>
          <hr />

          <p>
            Older students refine their techniques and gain practical self-defence skills. Training emphasises focus, confidence, and resilience, equipping them with the tools to stay safe and disciplined.
          </p>

          <hr />
          <h6 className="excerpt">What Your Child Will Learn</h6>
          <hr />

          <ul className="list-styled">
            <li>Respect & Self-Discipline</li>
            <li>Conflict Resolution & Confidence Building</li>
            <li>Essential Striking & Defensive Techniques</li>
            <li>Structured Grading & Progression</li>
            <li>Partner Drills & Sparring</li>
          </ul>

          <p>
            As students advance, they progress through our structured belt system, refining techniques and growing in confidence. More details can be found on our members&apos; website.
          </p>

          <hr />
          <h6 className="excerpt">Why Choose Karate for Your Child?</h6>
          <hr />

          <p>
            Karate instills discipline, improves fitness, and builds resilience. It fosters social connections and helps children develop valuable skills that benefit them in everyday life.
          </p>

          <hr />
          <h6 className="excerpt">Join Hills Combat Karate Today!</h6>
          <hr />

          <p>
            Our club is affiliated with the
            {' '}
            <Link to="/karate/the-english-karate-organisation">English Karate Organisation (EKO)</Link>
            , ensuring high-quality instruction and recognised grading.
          </p>

          <p>
            <hr />
            <strong>Location:</strong>
            {' '}
            Newton Regis Village Hall, Austrey Lane, Tamworth, B79 0NL
            {' '}
            <br />
            <hr />
            <strong>Class Time:</strong>
            {' '}
            Every Wednesday, 7 pm - 8 pm
            <hr />
          </p>

          <p>
            Want to learn more? Contact Rob at
            {' '}
            <a href="tel:+447930339609" title="Call Rob">07930 339609</a>
            {' '}
            or Mike at
            {' '}
            <a href="tel:+447860465577" title="Call Mike">07860 465577</a>
            . Book a free trial class and give your child the opportunity to thrive in a structured and supportive environment!
          </p>
        </>
      </Training>
    </>
  );
}

export default KidsTrainingNewtonRegis;

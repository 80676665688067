import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PageHeader from '../../../../components/PageHeader';
import OnlineTraining from '../../../../components/Training/Online';
import useLoginVerification from '../../../../hooks/useLoginVerification';
import Loader from '../../../../components/Loader';
import Unauthorised from '../../../../components/Training/Online/Unauthorised';

function HeianYondan() {
  const { validating, loggedIn, redirectIfNotLoggedIn } = useLoginVerification();

  useEffect(() => {
    redirectIfNotLoggedIn();
  }, []);

  return (
    <>
      <Helmet>
        <title>Heian Yondan | Peaceful Mind - Fourth Level | Hills Combat</title>
      </Helmet>

      <PageHeader name="Heian Yondan" />

      { validating && <Loader />}

      { (!validating && !loggedIn) && <Unauthorised /> }

      { !validating && loggedIn && (
      <OnlineTraining
        defaultVideo="/videos/kata/heian-yondan-front-view.mp4"
        title="Heian Yondan"
        subTitle="Peaceful Mind - Fourth Level"
        videos={[
          {
            src: '/videos/kata/heian-yondan-front-view.mp4',
            image: '/images/members-area/kata/heian-yondan-front-view.jpg',
            title: 'Heian Yondan (front view)',
          },
          {
            src: '/videos/kata/heian-yondan-rear-view.mp4',
            image: '/images/members-area/kata/heian-yondan-rear-view.jpg',
            title: 'Heian Yondan (rear view)',
          },
        ]}
      >
        <>
          <p>
            Turn left 90 degrees, and step forward left back stance, left upper level inside knife-hand block. Simultaneous right upper level knife-hand rising block. Arms form a rectangle with 90 degrees angles.
          </p>
          <p>
            Turn right 180 degrees pivoting on both feet to right back stance, right upper level inside knife-hand block. Simultaneous left upper level knife-hand rising block. Arms form a rectangle with 90 degrees angles.
          </p>
          <p>
            Turn left 90 degrees, pivoting on the right foot, step forward left front stance, downward x-block.
          </p>
          <p>
            Step forward right back stance, right middle augmented block.
          </p>
          <p>
            Bring left foot to right knee, bring right fist back into draw-hand by hip, bring left fist to rest on top of right fist. Simultaneously execute an upper-level horizontal left back-fist strike with a left leg side snap kick.
          </p>
          <p>
            Turn left 90 degrees, pivoting on the right foot, step forward left front stance, right front elbow strike into left palm.
          </p>
          <p>
            Turn right 90 degrees, pivoting on left foot, bring right foot to left knee, bring left fist back into draw-hand by hip, bring right fist to rest on top of left fist. Simultaneously execute an upper-level horizontal right back-fist strike with a right leg side snap kick.
          </p>
          <p>
            Turn right 90 degrees, pivoting on left foot, step forward right front stance, left front elbow strike into right palm.
          </p>
          <p>
            Feet in place, left downward knife-hand block, simultaneously bring right arm up to right upper-level knife-hand rising block.
          </p>
          <p>
            Turn left 90 degrees, pivoting on both feet, left front stance, left rising knife-hand block, simultaneous right upper knife-hand strike.
          </p>
          <p>
            Left foot in place, right middle front snap-kick.
          </p>
          <p>
            Step forward, right cross stance, right vertical back-fist strike (chest level), yell Kiai.
          </p>
          <p>
            Turn left 225 degrees, pivoting on right foot, step forward left back stance (slowly), cross arms in front of upper chest, then middle reverse wedge block (closed fists).
          </p>
          <p>
            Left foot in place, right middle front snap-kick.
          </p>
          <p>
            Step forward right front stance, middle combination punch (right lunge punch, then left reverse punch).
          </p>
          <p>
            Turn right 90 degrees, pivoting on left foot, right back stance (slowly), cross arms in front of upper chest, then middle reverse wedge block (closed fists).
          </p>
          <p>
            Right foot in place, left middle front snap-kick.
          </p>
          <p>
            Step forward left front stance, middle combination punch (left lunge punch, then right reverse punch).
          </p>
          <p>
            Turn left 45 degrees, pivoting on right foot, left back stance, left middle augmented block.
          </p>
          <p>
            Step forward, right back stance, right middle augmented block.
          </p>
          <p>
            Step forward, left back stance, left middle augmented block.
          </p>
          <p>
            Shift weight forward, left modified front stance (no side distance), reach forward with both hands to grasp sides of the opponent&apos;s head, then right rising knee strike, simultaneously bring closed fists down to sides of kicking knee, yell Kiai.
          </p>
          <p>
            Turn 180 degrees left, pivoting on left foot, step back into left back stance, left middle knife-hand block.
          </p>
          <p>
            Step forward right back stance, right middle knife-hand block.
          </p>
          <p>
            Pull right foot back into ready position.
          </p>
        </>
      </OnlineTraining>
      )}
    </>
  );
}

export default HeianYondan;
